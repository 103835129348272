/* import __COLOCATED_TEMPLATE__ from './language-selector.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { type Language } from 'embercom/lib/ai-content-library/constants';
import { FIN_SUPPORTED_LANGUAGES } from 'embercom/lib/ai-content-library/constants';
import { action } from '@ember/object';

interface DropDownOption {
  text: string;
  value: string;
}

export interface LanguageSelectorArgs {
  onChange: (locale: string | null) => void;
  selectedLocale?: string;
  disabled?: boolean;
  disableAutoLanguageDetection?: boolean;
}

export default class LanguageSelector extends Component<LanguageSelectorArgs> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @tracked supportedLanguages: DropDownOption[] = [];
  @tracked selectedLocale = 'en';

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.selectedLocale = args.selectedLocale || 'en';
    taskFor(this.initialiseSupportedLanguages).perform();
  }

  get app() {
    return this.appService.app;
  }

  get isLoading() {
    return taskFor(this.initialiseSupportedLanguages).isRunning;
  }

  @task({ drop: true })
  *initialiseSupportedLanguages(): TaskGenerator<void> {
    let languageSettings = yield this.store.findRecord(
      'messenger-settings/languages',
      this.appService.app.id,
    );
    let nonDefaultPermittedLocales = languageSettings.supportedLocales
      .filter((locale: any) => locale.isPermitted)
      .map((locale: any) => locale.localeId);
    let messengerSupportedLocales = [
      'en',
      languageSettings.defaultLocale,
      ...nonDefaultPermittedLocales,
    ].uniq();

    // Intersection of Fin supported locales and Messenger supported locales
    this.supportedLanguages = FIN_SUPPORTED_LANGUAGES.filter((language: Language) =>
      messengerSupportedLocales.includes(language.locale),
    ).map((language: Language) => {
      return {
        text: language.name,
        value: language.locale,
      };
    });
  }

  @action
  onLocaleClick(locale: string) {
    this.selectedLocale = locale;
    this.args.onChange(locale);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::Controls::LanguageSelector': typeof LanguageSelector;
    'workflows/preview/controls/language-selector': typeof LanguageSelector;
  }
}

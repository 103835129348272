/* import __COLOCATED_TEMPLATE__ from './update-attribute-operation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type AttributeInfoResolver } from '@intercom/embercom-prosemirror-composer';
import { tracked } from '@glimmer/tracking';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
interface Args {
  resolver: AttributeInfoResolver;
  onUpdateNode: (state: Record<string, unknown>) => void;
  onCreateAttribute: (attribute: OutputParam) => AttributeDescriptor;
  closePopover: () => void;
}

export default class UpdateAttributeOperation extends Component<Args> {
  @tracked showModal = false;

  @action
  onUpdateAttributeIdentifier(identifier: string): void {
    this.args.onUpdateNode({
      subjectIdentifier: identifier,
      attributeValueType: 'fin',
    });

    this.args.closePopover();
  }

  @action
  handleOnSubmit(attribute: OutputParam): void {
    let attr = this.args.onCreateAttribute(attribute);

    this.onUpdateAttributeIdentifier(attr.identifier);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AiPromptDataPopover::UpdateAttributeOperation': typeof UpdateAttributeOperation;
  }
}

/* import __COLOCATED_TEMPLATE__ from './attribute-pills.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

interface AttributeNamesAndIcons {
  name: string;
  icon: any;
}

interface Args {
  attributeNamesAndIcons: AttributeNamesAndIcons[];
  rounded?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class AttributePills extends Component<Signature> {
  truncateLimit = 5;

  get attributeNamesAndIcons() {
    let attributeNamesAndIcons = JSON.parse(
      JSON.stringify(this.args.attributeNamesAndIcons),
    ) as AttributeNamesAndIcons[];

    if (this.shouldTruncate) {
      attributeNamesAndIcons.splice(this.truncateLimit);
    }

    return attributeNamesAndIcons;
  }

  get truncatedAttributeNamesAndIcons() {
    let truncatedAttributeNamesAndIcons: AttributeNamesAndIcons[] = [];

    if (this.shouldTruncate) {
      truncatedAttributeNamesAndIcons = JSON.parse(
        JSON.stringify(this.args.attributeNamesAndIcons),
      ).slice(this.truncateLimit);
    }

    return truncatedAttributeNamesAndIcons;
  }

  get shouldTruncate() {
    return this.args.attributeNamesAndIcons.length > this.truncateLimit;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::WorkflowConnector::AttributePills': typeof AttributePills;
    'workflows/graph-editor/node-items/steps/workflow-connector/attribute-pills.ts': typeof AttributePills;
  }
}

/* import __COLOCATED_TEMPLATE__ from './node.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';

export default class WorkflowsGraphEditorNode extends Component {
  @service appService;
  @service intercomEventService;
  @service store;

  maxPathTitleLength = 255;
  title = this.group.title;

  get pathConfig() {
    return this.args.editorConfiguration.generatePathConfig({
      path: this.args.group,
      editorState: this.args.editorState,
    });
  }

  get hasFinStep() {
    return this.steps.any((step) =>
      ['operator/visual-builder/step/fin', 'operator/visual-builder/step/fin-custom'].includes(
        step.type,
      ),
    );
  }

  get connectionPoint() {
    return this.args.group.outwardConnectionPoint;
  }

  get isSortingDisabled() {
    return this.args.readOnly || this.steps.filter((step) => !step.isGroupEnding).length < 2;
  }

  get isSortingFirstStepDisabled() {
    return this.isSortingDisabled || !this.isFirstStepDeletable;
  }

  get isSortingLastStepDisabled() {
    return this.isSortingDisabled || this.group.hasGroupEndingStep;
  }

  get canDeletePath() {
    if (!this.args.readOnly && !this.args.group.isStart) {
      return true;
    } else {
      return false;
    }
  }

  get steps() {
    return this.group.steps;
  }

  get group() {
    return this.args.group;
  }

  get isFirstStepDeletable() {
    return this.pathConfig.canDeleteStep(this.steps.firstObject);
  }

  get canAddStepAboveLastStep() {
    return !this.args.readOnly && this.pathConfig.isAddStepEnabled && this.group.hasGroupEndingStep;
  }

  get canAddStepBelowLastStep() {
    return (
      !this.args.readOnly && this.pathConfig.isAddStepEnabled && !this.group.hasGroupEndingStep
    );
  }

  get isInwardConnectedEdgeMenuVisible() {
    return this.group.inwardEdges.length;
  }

  get outwardEdges() {
    let edges = this.group.steps.reduce((aggregatedEdges, step) => {
      let connectedEdges = step.outwardConnectionPoints.filterBy('isConnected').mapBy('edge');
      return aggregatedEdges.concat(connectedEdges);
    }, []);

    if (this.connectionPoint?.isConnected) {
      edges.push(this.connectionPoint.edge);
    }
    return edges;
  }

  get isOutwardConnectedEdgeMenuVisible() {
    return !this.group.isExpanded && this.outwardEdges.length;
  }

  @action
  toggleExpandCollapse() {
    this.group.toggleExpand();
    if (!this.group.isExpanded) {
      this.args.editorState.unregisterOutwardConnectionPointElementsForGroup(this.group);
    }
  }

  @action
  reorderSteps(sortedSteps, step) {
    let steps = [
      ...(this.isSortingFirstStepDisabled ? [this.steps.firstObject] : []),
      ...sortedSteps,
      ...(this.isSortingLastStepDisabled ? [this.steps.lastObject] : []),
    ];

    set(this.group, 'steps', steps);

    this.intercomEventService.trackAnalyticsEvent({
      object: step,
      action: 'reordered',
    });

    this.args.editorState.markAsEdited();
  }

  @action
  trackTitleChange() {
    let previousTitle = this.title ?? '';
    let currentTitle = this.group.title ?? '';
    if (previousTitle.trim() !== currentTitle.trim()) {
      this.title = currentTitle;
      this.intercomEventService.trackAnalyticsEvent({
        ...this.group.analyticsData,
        object: 'visual_builder_path_title',
        action: 'updated',
        current_title_length: currentTitle.length,
        previous_title_length: previousTitle.length,
      });
    }
  }
}

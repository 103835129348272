/* import __COLOCATED_TEMPLATE__ from './custom-action-header-content.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  workflowAction: $TSFixMe;
  deleteAction: () => void;
  isSaveDisabled: boolean;
  saveErrorMessage: string;
  saveAction: () => void;
  setLive: () => void;
  setLiveErrorMessage: string;
  moveToDraft: () => void;
  isSaving: boolean;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class CustomActionHeaderContent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  get groupList() {
    return [
      {
        items: [
          ...(this.appService.app.isStandaloneApp
            ? []
            : [
                {
                  text: this.intl.t('workflow-connector.actions.hero-banner.learn-more'),
                  icon: 'article',
                  onSelectItem: () =>
                    safeWindowOpen(
                      'https://www.intercom.com/help/en/collections/3510275-custom-actions-objects',
                      '_blank',
                    ),
                },
              ]),
          {
            text: this.intl.t('workflow-connector.builder.side-drawer.button.delete'),
            icon: 'trash',
            isDestructive: true,
            onSelectItem: () => this.args.deleteAction(),
            isDisabled: this.args.workflowAction.state !== 'draft',
            tooltipText: this.intl.t(
              'workflow-connector.builder.side-drawer.button.delete-tooltip',
            ),
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::CustomActionHeaderContent': typeof CustomActionHeaderContent;
    'workflow-connector/custom-action-header-content': typeof CustomActionHeaderContent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './parameters.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ParamDescriptor from 'embercom/models/workflow-connector/param-descriptor';

interface Args {
  usage: string;
  paramDescriptors: ParamDescriptor[];
  updateParamDescriptor: (
    index: string | undefined,
    name: string,
    description: string,
    type: string,
    required: boolean,
    defaultValue: string,
  ) => void;
  deleteParamDescriptor: (name: string) => void;
  usedAttributes: string[];
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class Parameters extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked showModal = false;
  @tracked selectedFormat = 'string';
  @tracked index: string | undefined;
  @tracked name: string | undefined;
  @tracked description: string | undefined;

  get columns() {
    return [
      {
        label: this.intl.t('workflow-connector.builder.body.request.fin-data.table.name'),
        valuePath: 'name',
        isSortable: true,
      },
      {
        label: this.intl.t('workflow-connector.builder.body.request.fin-data.table.format'),
        valuePath: 'type',
        isSortable: true,
      },
      {
        label: this.intl.t('workflow-connector.builder.body.request.fin-data.table.description'),
        valuePath: 'description',
        isSortable: true,
      },
      {
        label: '',
        valuePath: 'actions',
      },
    ];
  }

  get formatOptions() {
    return [
      {
        value: 'string',
        text: this.intl.t(
          'workflow-connector.builder.body.request.fin-data.modal.form.format-types.string',
        ),
        icon: 'field',
      },
      {
        value: 'integer',
        text: this.intl.t(
          'workflow-connector.builder.body.request.fin-data.modal.form.format-types.integer',
        ),
        icon: 'numbers',
      },
      {
        value: 'decimal',
        text: this.intl.t(
          'workflow-connector.builder.body.request.fin-data.modal.form.format-types.decimal',
        ),
        icon: 'decimal',
      },
      {
        value: 'boolean',
        text: this.intl.t(
          'workflow-connector.builder.body.request.fin-data.modal.form.format-types.boolean',
        ),
        icon: 'boolean',
      },
    ];
  }

  get canSave() {
    return this.name && this.description;
  }

  clearModal() {
    this.index = undefined;
    this.selectedFormat = 'string';
    this.name = undefined;
    this.description = undefined;
  }

  @action
  editParamDescriptor(data: any) {
    this.index = data.name;
    this.name = data.name;
    this.description = data.description;
    this.selectedFormat = data.type;
    this.showModal = true;
  }

  @action
  deleteParamDescriptor(data: any) {
    this.args.deleteParamDescriptor(data.name);
  }

  @action
  onFormatChange(format: string) {
    this.selectedFormat = format;
  }

  @action
  onCancel() {
    this.clearModal();
    this.showModal = false;
  }

  @action
  onSave() {
    if (this.name && this.description) {
      this.args.updateParamDescriptor(
        this.index,
        this.name,
        this.description,
        this.selectedFormat,
        false,
        '',
      );
      this.showModal = false;
      this.clearModal();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Request::Parameters': typeof Parameters;
    'workflow-connector/builder/body/sections/request/parameters': typeof Parameters;
  }
}

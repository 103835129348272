/* import __COLOCATED_TEMPLATE__ from './messenger-preview.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

interface Args {
  messengerUrl: string;
  onPreviewLoaded?: () => void;
  previewRefreshing?: boolean;
  onMessage?: (event: MessageEvent) => void;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const IFRAME_CLASSES = 'w-full h-full rounded-module';

export default class MessengerPreview extends Component<Signature> {
  @tracked isLoadingIframe = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    window.addEventListener('message', this.onMessage);
  }

  isValidJSON(data: unknown) {
    try {
      JSON.parse(data as string);
      return true;
    } catch (error) {
      return false;
    }
  }

  @action
  onMessage(event: MessageEvent) {
    if (!this.isValidJSON(event.data)) {
      return;
    }

    let data = JSON.parse(event.data);
    this.args.onMessage?.(data);
  }

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('message', this.onMessage);
  }

  @action
  onIframeLoaded() {
    this.args.onPreviewLoaded?.();
    setTimeout(() => {
      this.isLoadingIframe = false;
    }, 2000);
  }

  bindIFrame = modifier((el) => {
    let iframe = document.createElement('iframe');

    iframe.addEventListener('load', this.onIframeLoaded);

    iframe.src = this.args.messengerUrl;
    this.isLoadingIframe = true;

    iframe.setAttribute('id', 'hosted-messenger-unified-preview');
    iframe.setAttribute('class', IFRAME_CLASSES);

    el.replaceChildren(iframe);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::MessengerPreview': typeof MessengerPreview;
    'workflows/preview/messenger-preview': typeof MessengerPreview;
  }
}

/* import __COLOCATED_TEMPLATE__ from './mock-response.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type ApiRequestConfig from 'embercom/models/workflow-connector/customer-data-verification/api-request-config';

interface Args {
  apiRequestConfig: ApiRequestConfig;
}

export default class MockResponse extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;

  mockResponseBody =
    this.mockResponse?.body || '{ \n  "example_key": "example value (please edit)"\n}';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (!this.mockResponse) {
      this.args.apiRequestConfig.mockResponse = this.store.createFragment(
        'workflow-connector/customer-data-verification/mock-response',
      );
    }
  }

  get mockResponse() {
    return this.args.apiRequestConfig?.mockResponse;
  }

  @action
  updateResponseBody(value: string) {
    this.mockResponse.body = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::CustomerDataVerification::Sections::Testing::MockResponse': typeof MockResponse;
    'workflow-connector/builder/body/customer-data-verification/sections/testing/mock-response': typeof MockResponse;
  }
}

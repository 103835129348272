/* import __COLOCATED_TEMPLATE__ from './add-output.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { Type as DescriptorType } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
interface Args {
  outputParams: OutputParam[];
  editorState: EditorState;
  readOnly: boolean;
  onAddOutputParam: (outputParam: OutputParam) => AttributeDescriptor;
  onUpdateOutputParam: (outputParam: OutputParam) => void;
  onRemoveOutputParam: (outputParam: OutputParam) => void;
}

interface Signature {
  Args: Args;
}
export default class AddOutput extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked modalIsVisible = false;
  @tracked selectedOutputParam?: OutputParam;

  @action
  formatTypeLabel(descriptorType: DescriptorType) {
    switch (descriptorType) {
      case DescriptorType.string:
        return this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.string',
        );
      case DescriptorType.integer:
        return this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.integer',
        );
      case DescriptorType.boolean:
        return this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.boolean',
        );
      case DescriptorType.workflow_attribute_descriptor_list:
        return this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.list',
        );
      default:
        return '';
    }
  }

  @action
  showModal(outputParam?: OutputParam) {
    this.modalIsVisible = true;
    this.selectedOutputParam = outputParam;
  }

  @action
  hideModal() {
    this.modalIsVisible = false;
    this.selectedOutputParam = undefined;
  }

  @action
  addOrUpdateOutputParam(outputParam: OutputParam) {
    if (outputParam.id) {
      this.args.onUpdateOutputParam(outputParam);
    } else {
      this.args.onAddOutputParam(outputParam);
    }

    this.hideModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AddOutput': typeof AddOutput;
    'workflows/graph-editor/node-items/steps/fin-custom/add-output': typeof AddOutput;
  }
}

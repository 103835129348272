/* import __COLOCATED_TEMPLATE__ from './custom-action.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';

export default class CustomAction extends Component {
  @service store;
  @service router;
  @service notificationsService;
  @service intercomEventService;
  @service intl;
  @service intercomConfirmService;
  @service appService;

  @tracked selectedTab = this.router?.currentRoute?.queryParams?.selectedTab || 'general_section';
  @tracked showCustomActionDraftModal = false;
  @tracked showSetLiveConfirmationModal = false;
  @tracked showDependentBackgroundWorkflowsModal = false;
  @tracked backgroundWorkflows = [];
  @tracked hasDependentBackgroundWorkflows = null;
  @tracked isSaving = false;

  get app() {
    return this.appService.app;
  }

  get breadcrumbsRoute() {
    return 'apps.app.settings.app-settings.custom-actions';
  }

  get isGeneralSectionValid() {
    return this.args.workflowAction.isGeneralConfigurationValid;
  }

  get isRequestSectionValid() {
    return this.args.workflowAction.isRequestConfigurationValid;
  }

  get isTestingSectionValid() {
    return this.args.workflowAction.hasValidResponse;
  }

  get isResponseSectionValid() {
    return this.args.workflowAction.isResponseConfigurationValid;
  }

  get setLiveErrorMessage() {
    let invalidSections = [];
    if (!this.isGeneralSectionValid) {
      invalidSections.push(this.intl.t('workflow-connector.builder.tab-control.general'));
    }
    if (!this.isRequestSectionValid) {
      invalidSections.push(this.intl.t('workflow-connector.builder.tab-control.request'));
    }
    if (!this.isTestingSectionValid) {
      invalidSections.push(this.intl.t('workflow-connector.builder.tab-control.testing'));
    }
    if (invalidSections.length > 0) {
      return this.intl.t(
        'workflow-connector.builder.side-drawer.button.validation-failed-for-following-steps',
        { steps: invalidSections.join(', ') },
      );
    }

    if (this.args.workflowAction.isTestResponse && !this.args.workflowAction.testIsValid) {
      return this.intl.t('workflow-connector.builder.side-drawer.button.not-tested-error');
    }

    if (this.args.workflowAction.isMockResponse && !this.args.workflowAction.mockIsValid) {
      return this.intl.t('workflow-connector.builder.side-drawer.button.invalid-mock-error');
    }

    if (!this.args.workflowAction.isValid) {
      return this.intl.t('workflow-connector.builder.side-drawer.button.validation-failed-error');
    }
  }

  get responseSectionLabel() {
    return this.args.workflowAction.usage === 'fin'
      ? this.intl.t('workflow-connector.builder.tab-control.preview')
      : this.intl.t('workflow-connector.builder.tab-control.data-access');
  }

  get responseSectionDisabledTooltip() {
    if (this.app.canUseConsolidatedActions) {
      return this.intl.t(
        'workflow-connector.builder.tab-control.test-response-required-tooltip-general',
      );
    }

    return this.args.workflowAction.usage === 'fin'
      ? this.intl.t(
          'workflow-connector.builder.tab-control.test-response-required-tooltip-for-fin-usage',
        )
      : this.intl.t(
          'workflow-connector.builder.tab-control.test-response-required-tooltip-general',
        );
  }

  get isResponseSectionEnabled() {
    return this.args.workflowAction.hasValidResponse;
  }

  get saveErrorMessage() {
    if (this.args.workflowAction.state === 'live') {
      if (this.args.workflowAction.isTestResponse && !this.args.workflowAction.testIsValid) {
        return this.intl.t('workflow-connector.builder.side-drawer.button.save-not-tested-error');
      }

      if (!this.args.workflowAction.isValid) {
        return this.intl.t('workflow-connector.builder.side-drawer.button.save-validation-error');
      }
    }
  }

  get isSaveDisabled() {
    if (this.args.workflowAction.state === 'live') {
      return !this.args.workflowAction.isValid || !this.args.workflowAction.hasUnsavedChanges;
    } else {
      return !this.args.workflowAction.hasUnsavedChanges;
    }
  }

  get shouldShowSetLiveConfirmationModal() {
    return (
      this.args.workflowAction.isFinStandaloneAction &&
      this.app?.canUseFeature('team-actions-allow-no-authentication') &&
      !this.args.workflowAction.isSecuritySettingsValid
    );
  }

  scrollToSecuritySettingsSection(retryCount = 0) {
    let MAX_RETRIES = 5; // Prevent infinite loops by limiting retries
    let element = document.getElementById('custom-action-security-settings-section');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else if (retryCount < MAX_RETRIES) {
      requestAnimationFrame(() => this.scrollToSecuritySettingsSection(retryCount + 1));
    }
  }

  @action
  onSelectionChange(sectionId) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'custom_action_tab',
      tab_name: sectionId,
      action_id: this.args.workflowAction.id,
    });
    this.selectedTab = sectionId;
  }

  @task({ drop: true })
  *fetchBackgroundWorkflows() {
    let action = this.args.workflowAction;
    let params = {
      app_id: this.app.id,
      id: this.args.workflowAction.id,
      fetch_only_background_workflows: true,
    };
    try {
      let response = yield get(
        `/ember/workflow_connector/actions/${action.id}/find_dependent_objects`,
        params,
      );
      this.backgroundWorkflows = response.dependent_background_workflows || [];
      this.hasDependentBackgroundWorkflows = response.has_dependents;
      return this.hasDependentBackgroundWorkflows;
    } catch (error) {
      this.notificationsService.notifyError(
        'Something went wrong while turning on the One time Password verification.',
      );
    }
  }

  @action
  deleteAction() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'delete_action',
      action_id: this.args.workflowAction.id,
    });
    this.deleteActionTask.perform();
  }

  @task
  *deleteActionTask() {
    let confirmOptions = {
      title: this.intl.t('workflow-connector.builder.delete-modal.title'),
      confirmButtonText: this.intl.t('workflow-connector.builder.delete-modal.delete-button'),
      cancleButtonText: this.intl.t('workflow-connector.builder.delete-modal.cancel-button'),
      body: this.intl.t('workflow-connector.builder.delete-modal.body'),
      primaryButtonType: 'primary-destructive',
    };

    let isConfirmed = yield this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }

    try {
      yield this.args.workflowAction.destroyRecord();
      this.notificationsService.notifyConfirmation(`Action successfully deleted`);
      this.router.transitionTo('apps.app.settings.app-settings.custom-actions.index');
    } catch (e) {
      this.notificationsService.notifyError(`There was a problem deleting the action`);
    }
  }

  @action
  async saveAction() {
    this.isSaving = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'save_and_close',
      action_id: this.args.workflowAction.id,
    });

    if (this.args.workflowAction.state === 'live' && this.args.workflowAction.otpAuthEnabled) {
      let hasBackgroundWorkflows = await this.fetchBackgroundWorkflows.perform();
      if (hasBackgroundWorkflows) {
        this.showDependentBackgroundWorkflowsModal = true;
        return;
      }
    }

    if (this.args.workflowAction.state === 'live' && this.shouldShowSetLiveConfirmationModal) {
      this.showSetLiveConfirmationModal = true;
      return;
    }

    await this.saveActionTask.perform();
    this.isSaving = false;
  }

  @task
  *saveActionTask() {
    try {
      if (['get', 'delete'].includes(this.args.workflowAction.httpMethod)) {
        this.args.workflowAction.body = null;
      }

      if (!this.args.workflowAction.testIsValid && this.args.workflowAction.testResult) {
        this.args.workflowAction.testResult.actionResponse = null;
      }

      if (
        this.args.workflowAction.usage === 'workflow_and_inbox' &&
        this.args.workflowAction.ruleset?.hasDirtyAttributes
      ) {
        this.args.workflowAction.ruleset.rollbackAttributes();
      }

      yield this.args.workflowAction.save();
      this.args.workflowAction.hydrateMappingObjects();
      this.notificationsService.notifyConfirmation(`Action successfully updated`);
    } catch (e) {
      this.notificationsService.notifyError(`There was a problem updating the action`);
    }
  }

  @action
  goToSecuritySettings() {
    this.args.workflowAction.setNoAuthenticationChosen(false);
    this.selectedTab = 'general_section';
    this.scrollToSecuritySettingsSection();
    this.showSetLiveConfirmationModal = false;
  }

  @action
  acceptSetLiveConfirmation() {
    this.showSetLiveConfirmationModal = false;
    this.args.workflowAction.setNoAuthenticationChosen(true);
    this.setLiveTask.perform();
  }

  @action
  setLive() {
    if (!this.args.workflowAction.isValid) {
      return this.notificationsService.notifyError(
        `The action cannot be set live until action is valid`,
      );
    }

    if (this.shouldShowSetLiveConfirmationModal) {
      this.showSetLiveConfirmationModal = true;
    } else {
      this.setLiveTask.perform();
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'set_live',
      action_id: this.args.workflowAction.id,
    });
  }

  @task
  *setLiveTask() {
    let previousState = this.args.workflowAction.state;
    try {
      this.args.workflowAction.state = 'live';
      yield this.args.workflowAction.save();
      this.notificationsService.notifyConfirmation(`Action successfully set Live`);
    } catch (e) {
      this.args.workflowAction.state = previousState;
      this.notificationsService.notifyError(`The action could not be set to Live`);
    }
  }

  @action
  closeModals() {
    this.showCustomActionDraftModal = false;
  }

  @action
  moveToDraft() {
    this.showCustomActionDraftModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'set_draft',
      action_id: this.args.workflowAction.id,
    });
  }

  @task
  *moveToDraftTask() {
    let previousState = this.args.workflowAction.state;
    try {
      this.args.workflowAction.state = 'draft';
      if (this.app?.canUseFeature('team-actions-allow-no-authentication')) {
        this.args.workflowAction.setNoAuthenticationChosen(false);
      }
      yield this.args.workflowAction.save();
      this.showCustomActionDraftModal = false;
      this.notificationsService.notifyConfirmation(`Action successfully moved to Draft state`);
    } catch (e) {
      this.args.workflowAction.state = previousState;
      this.notificationsService.notifyError(`The action could not be set to Draft`);
    }
  }
}

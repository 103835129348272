/* import __COLOCATED_TEMPLATE__ from './user-profile-actions-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { action } from '@ember/object';
import { readOnly, and, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import DetectsIntercomradesDomain from 'embercom/lib/detects-intercomrades-domain';
import { impersonateInRegion } from 'embercom/lib/impersonate';

export default Component.extend(DetectsIntercomradesDomain, {
  classNames: ['u__left'],
  appService: service(),
  app: readOnly('appService.app'),
  highlightListItemClass: '',

  shouldDisplayImpersonateUserOption: and(
    'isIntercomradesDomain',
    'app.isNotImpersonationSession',
    'model.isNotAnonymous',
  ),
  userUnsubscribedFromEmails: reads('model.unsubscribed_from_emails'),
  impersonateUser: action(function (region, userId, email) {
    impersonateInRegion(region, userId, email, this.app);
  }),
});

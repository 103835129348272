/* import __COLOCATED_TEMPLATE__ from './testing.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ContextualIdentifierConfig from 'embercom/models/workflow-connector/customer-data-verification/contextual-identifier-config';
import { tracked } from '@glimmer/tracking';

interface Args {
  contextualIdentifierConfig: ContextualIdentifierConfig;
  templatedParameters: any;
  clearTemplatedParameter: () => void;
  saveTemplatedParameter: (requestParameter: any, requestValue: any) => void;
}

export default class Testing extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: any;

  @tracked configurationResponseType = 'test_response_type';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get apiRequestConfig() {
    return this.args.contextualIdentifierConfig.apiRequestConfig;
  }

  get isMockConfigurationSelected() {
    return this.apiRequestConfig?.configurationResponseType === 'mock_response_type';
  }

  @action
  setResponseTypeToTest() {
    this.apiRequestConfig.configurationResponseType = 'test_response_type';
  }

  @action
  setResponseTypeToMock() {
    this.apiRequestConfig.configurationResponseType = 'mock_response_type';
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::CustomerDataVerification::Sections::Testing': typeof Testing;
    'workflow-connector/builder/body/customer-data-verification/sections/testing': typeof Testing;
  }
}

/* import __COLOCATED_TEMPLATE__ from './use-action-popover.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';
import { iconByType } from 'embercom/components/workflows/graph-editor/helpers/template-helpers';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { isPresent } from '@ember/utils';
import { CUSTOM_ACTION_URL } from './use-action-operation';

interface Args {
  insertableAction: InsertableAction;
}

export default class UseActionPopover extends Component<Args> {
  @service declare router: $TSFixMe;

  get hasFinCollectedData() {
    return this.args.insertableAction.hasRequestParameters;
  }

  get hasApiParameters() {
    return (
      isPresent(this.args.insertableAction.request_attribute_identifiers) &&
      this.args.insertableAction.request_attribute_identifiers.length > 0
    );
  }

  get finCollectedDataAttributes() {
    return this.args.insertableAction.requestParameters.map((attribute: any) => {
      return {
        name: attribute.name,
        icon: iconByType(attribute.type),
      };
    });
  }

  get apiParameterAttributes() {
    return this.args.insertableAction.requestAttributeIdentifiersArray.map((attribute: any) => {
      return {
        name: attribute,
        icon: iconByType(attribute),
      };
    });
  }

  @action
  onActionReponseData(): void {
    safeWindowOpen(this.router.urlFor(CUSTOM_ACTION_URL, this.args.insertableAction.id), '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AiPromptDataPopover::UseActionPopover': typeof UseActionPopover;
  }
}

/* import __COLOCATED_TEMPLATE__ from './ai-prompt-data-popover.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import type AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';
import { TextSelection, type NodeSelection } from 'prosemirror-state';
import type { Node as ProsemirrorNode } from 'prosemirror-model';
import { action } from '@ember/object';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { inject as service } from '@ember/service';

interface Signature {
  Element: HTMLElement;
  Args: Args;
}
interface Args {
  composer: Composer;
}

export default class AiPromptDataPopover extends Component<Signature> {
  @service declare store: $TSFixMe;

  get attributes(): { id: string; label: string }[] {
    return this.resolver?.attributes ?? [];
  }

  get composer(): Composer {
    return this.args.composer;
  }

  get node(): ProsemirrorNode {
    let selection = this.composer.state.prosemirrorState.selection as NodeSelection;
    return selection?.node;
  }

  get isVisible(): boolean {
    let spec = this.composer.state.prosemirrorState.schema.nodes.aiPromptData;
    return (
      spec &&
      this.node?.type === spec &&
      ((this.isReadAttributeOperation && !this.node?.attrs.subjectIdentifier) ||
        (this.isUpdateAttributeOperation && !this.node?.attrs.subjectIdentifier) ||
        this.isUseActionOperation)
    );
  }

  get resolver(): AttributeInfoResolver {
    return this.composer.config.aiPromptData?.resolver as AttributeInfoResolver;
  }

  get isReadAttributeOperation(): boolean {
    return this.node?.attrs.operation === 'read_attribute';
  }

  get isUpdateAttributeOperation(): boolean {
    return this.node?.attrs.operation === 'update_attribute';
  }

  get isUseActionOperation(): boolean {
    return this.node?.attrs.operation === 'use_action';
  }

  get hasSubjectIdentifier(): boolean {
    return this.subjectIdentifier !== undefined && this.subjectIdentifier !== '';
  }

  get subjectIdentifier(): string {
    return typeof this.node?.attrs.subjectIdentifier === 'string'
      ? this.node?.attrs.subjectIdentifier
      : '';
  }

  get insertableAction() {
    let extractedId = this.subjectIdentifier.match(/\d+/);
    let actionId = extractedId ? parseInt(extractedId[0], 10) : null;
    return this.store.peekRecord('workflow-connector/insertable-action', actionId);
  }

  @action
  updateNode(state: Record<string, unknown>): void {
    this.composer.commands.updateSelectedNode(state);
  }

  @action
  createAttribute(attribute: OutputParam): AttributeDescriptor | undefined {
    let attr = this.composer.config.aiPromptData?.onAddOutputParam(attribute);
    if (!attr) {
      return;
    }

    let tempAttr = this.resolver.allLocalAttributes.find(
      (attr: any) => attr.heading === 'Temporary attributes',
    );
    if (tempAttr) {
      tempAttr.attributes.push(attr);
    }

    return attr;
  }

  @action
  closePopover(): void {
    let view = this.composer.state.editorView;
    let pos = view.state.selection.$anchor.pos;

    // Find the depth of the current position
    let _pos = view.state.doc.resolve(pos);
    let depth = _pos.depth;

    // Find the end of the node at this position
    let endPos = _pos.after(depth);

    // Set selection right after the node
    let tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, endPos));

    view.dispatch(tr);
    view.focus();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AiPromptDataPopover': typeof AiPromptDataPopover;
    'workflows/graph-editor/node-items/steps/fin-custom/ai-prompt-data-popover': typeof AiPromptDataPopover;
  }
}

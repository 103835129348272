/* import __COLOCATED_TEMPLATE__ from './edit-attribute-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { TrackedArray } from 'tracked-built-ins';
import { tracked } from '@glimmer/tracking';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';
import { Type as DescriptorType } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { EMBERCOM_PROSEMIRROR_COMPOSER_ALLOW_MOUSEDOWN_CLASS } from '@intercom/embercom-prosemirror-composer/components/editor-api/constants';
interface Signature {
  Element: Element;
  Args: {
    attribute: OutputParam | undefined;
    onCancel: () => void;
    onSubmit: (outputParam: OutputParam) => void;
  };
}

class ListOption {
  id?: string;
  @tracked value: string;

  constructor(id: string | undefined, value = '') {
    this.id = id;
    this.value = value;
  }
}

class BackingObject {
  id?: string;
  @tracked name?: string;
  @tracked description?: string;
  @tracked descriptorType: DescriptorType = DescriptorType.string;
  @tracked listOptions: TrackedArray<ListOption> = new TrackedArray<ListOption>();

  constructor(outputParam?: OutputParam) {
    if (outputParam) {
      this.id = outputParam.id;
      this.name = outputParam.name;
      this.description = outputParam.description;
      this.descriptorType = outputParam.descriptorType;

      for (let option of outputParam?.listOptions ?? []) {
        this.listOptions.push(new ListOption(option.id, option.value));
      }
    }
  }

  get isNew() {
    return !this.id;
  }

  get hasListOptions() {
    return this.listOptions.length > 0;
  }

  get hasBlankListOptions() {
    return this.listOptions.some((option) => !option.value || option.value === '');
  }

  get hasDuplicateListOptions() {
    return new Set(this.listOptions.map((option) => option.value)).size < this.listOptions.length;
  }

  get isValid() {
    return (
      this.name &&
      this.name !== '' &&
      this.description &&
      this.description !== '' &&
      !(
        this.descriptorType === DescriptorType.workflow_attribute_descriptor_list &&
        (this.hasBlankListOptions || this.hasDuplicateListOptions)
      )
    );
  }

  addListOption(text: string) {
    this.listOptions.push(new ListOption(undefined, text));
  }

  removeListOption(index: number) {
    this.listOptions.splice(index, 1);
  }

  toOutputParam() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      descriptorType: this.descriptorType,
      listOptions: this.listOptions
        .map((option) => {
          if (option.value !== '') {
            return {
              id: option.id,
              value: option.value,
            };
          }

          return null;
        })
        .filter(Boolean),
    } as OutputParam;
  }
}

export default class EditAttributeModal extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked backingObject?: BackingObject;

  readonly classNameToPreventComposerStealingFocus =
    EMBERCOM_PROSEMIRROR_COMPOSER_ALLOW_MOUSEDOWN_CLASS;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);

    this.backingObject = new BackingObject(this.args.attribute);
  }

  get temporarilyDisableAttributeTypeSelectionUntilAnswerbotSupportsIt() {
    return true;
  }

  get outputTypeOptions() {
    let options = [
      {
        text: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.string',
        ),
        icon: 'field',
        value: DescriptorType.string,
      },
      {
        text: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.integer',
        ),
        icon: 'numbers',
        value: DescriptorType.integer,
      },
      {
        text: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.boolean',
        ),
        icon: 'boolean',
        value: DescriptorType.boolean,
      },
      {
        text: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.results.attribute-descriptor-formats.list',
        ),
        icon: 'list',
        value: DescriptorType.workflow_attribute_descriptor_list,
      },
    ];

    if (
      this.temporarilyDisableAttributeTypeSelectionUntilAnswerbotSupportsIt &&
      this.backingObject?.isNew
    ) {
      return options.filter((option) => option.value === DescriptorType.string);
    }

    return options;
  }

  @action
  updateDescriptorType(descriptorType: DescriptorType) {
    this.backingObject!.descriptorType = descriptorType;
    if (descriptorType === DescriptorType.workflow_attribute_descriptor_list) {
      if (!this.backingObject!.hasListOptions) {
        this.backingObject!.addListOption('');
        this.backingObject!.addListOption('');
      }
    }
  }

  @action
  addListOption(text: string) {
    this.backingObject!.addListOption(text);
  }

  @action
  removeListOption(index: number) {
    this.backingObject!.removeListOption(index);
  }

  @action
  onSubmit() {
    this.args.onSubmit(this.backingObject!.toOutputParam());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::EditAttributeModal': typeof EditAttributeModal;
    'workflows/graph-editor/node-items/steps/fin-custom/edit-attribute-modal': typeof EditAttributeModal;
  }
}

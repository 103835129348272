/* import __COLOCATED_TEMPLATE__ from './channel-selector.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';

export interface ChannelSelectorArgs {
  onClick?: (channel: string) => void;
  currentChannel: 'email' | 'chat';
}

export default class ChannelSelector extends Component<ChannelSelectorArgs> {
  constructor(owner: unknown, args: ChannelSelectorArgs) {
    super(owner, args);
  }

  get icon() {
    return this.args.currentChannel === 'email' ? 'chat-bubble' : 'message';
  }

  get tooltipKey() {
    return this.args.currentChannel === 'email'
      ? 'operator.custom-bot.editor.preview.controls.channel-selector.preview-chat'
      : 'operator.custom-bot.editor.preview.controls.channel-selector.preview-email';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::Controls::ChannelSelector': typeof ChannelSelector;
    'workflows/preview/controls/channel-selector': typeof ChannelSelector;
  }
}

/* import __COLOCATED_TEMPLATE__ from './handoff-to-salesforce-in-app-messaging.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';
import type HandoffToSalesforceInAppMessagingStepType from 'embercom/models/operator/visual-builder/step/handoff-to-salesforce-in-app-messaging';
import type HandoffToSalesforceInAppMessagingConfiguration from 'embercom/objects/visual-builder/configuration/step/handoff-to-salesforce-in-app-messaging';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

export interface HandoffToSalesforceInAppMessagingArgs {
  step: HandoffToSalesforceInAppMessagingStepType;
  stepConfig: HandoffToSalesforceInAppMessagingConfiguration;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: HandoffToSalesforceInAppMessagingArgs;
}

const HandoffToSalesforceInAppMessaging = templateOnlyComponent<Signature>();

export default HandoffToSalesforceInAppMessaging;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffToSalesforceInAppMessaging': typeof HandoffToSalesforceInAppMessaging;
    'workflows/graph-editor/node-items/steps/handoff-to-salesforce-in-app-messaging': typeof HandoffToSalesforceInAppMessaging;
  }
}

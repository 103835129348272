/* import __COLOCATED_TEMPLATE__ from './instruction.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import Component from '@glimmer/component';
import {
  type BlocksDocument,
  type ComposerPublicAPI,
} from '@intercom/embercom-prosemirror-composer';
import type ComposerConfig from 'embercom/objects/workflows/graph-editor/chat-message/composer-config';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Signature {
  Args: Arguments;
  Element: HTMLElement;
}

interface Arguments {
  blocksDoc: BlocksDocument;
  onChange: (blocksDoc: BlocksDocument) => void;
  onReady: (composerApi: ComposerPublicAPI) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isReadOnly: boolean;
  config: ComposerConfig;
}
export default class Instruction extends Component<Signature> {
  @tracked _internalBlockDocState: BlocksDocument;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this._internalBlockDocState = args.blocksDoc;
  }

  get blocksDoc() {
    return this._internalBlockDocState || this.args.blocksDoc;
  }

  @action
  onChange(blocksDoc: BlocksDocument) {
    this._internalBlockDocState = blocksDoc;
    this.args.onChange(blocksDoc);
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::Composers::Instruction': typeof Instruction;
    'workflows/graph-editor/node-items/steps/fin-custom/composers/instruction': typeof Instruction;
  }
}

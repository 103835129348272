/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { startTour } from 'embercom/lib/intercom-widget-helper';

export default class StartTour extends Component {
  constructor() {
    super(...arguments);
    startTour(this.args.tourId);
    this.args.onTourStart();
  }
}

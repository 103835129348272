/* import __COLOCATED_TEMPLATE__ from './fin-task-simple-mode-node.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type Group from 'embercom/models/operator/visual-builder/group';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';

interface Args {
  editorConfiguration: EditorConfig;
  group: Group;
  editorState: EditorState;
  readOnly: boolean;
}

export default class FinTaskSimpleModeNode extends Component<Args> {
  get pathConfig() {
    return this.args.editorConfiguration.generatePathConfig({
      path: this.args.group,
      editorState: this.args.editorState,
    });
  }

  get step() {
    return this.args.group.steps?.firstObject;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::FinTaskSimpleModeNode': typeof FinTaskSimpleModeNode;
    'workflows/graph-editor/fin-task-simple-mode-node': typeof FinTaskSimpleModeNode;
  }
}

/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import type Action from 'embercom/models/workflow-connector/action';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  workflowAction: Action;
  nextSection: (sectionId: string) => void;
}

interface Signature {
  Args: Args;
}

export default class General extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;

  ignoredAttributeTypesForPreview = ['message', 'conversation', 'office_hours', 'team_capacity'];

  get supportedActionUsage() {
    return [
      {
        text: this.intl.t('workflow-connector.builder.body.general.usage.fin.option'),
        value: 'fin',
        description: this.intl.t('workflow-connector.builder.body.general.usage.fin.description'),
      },
      {
        text: this.intl.t('workflow-connector.builder.body.general.usage.workflow-inbox.option'),
        value: 'workflow_and_inbox',
        description: this.intl.t(
          'workflow-connector.builder.body.general.usage.workflow-inbox.description',
        ),
      },
    ];
  }

  get descriptionTitle() {
    if (this.args.workflowAction.usage === 'fin') {
      return this.intl.t(
        'workflow-connector.builder.body.general.fin-trigger-guidance.description.title',
      );
    } else {
      return this.intl.t('workflow-connector.builder.body.general.description.label');
    }
  }

  get descriptionPlaceholder() {
    if (this.args.workflowAction.usage === 'fin') {
      return this.intl.t(
        'workflow-connector.builder.body.general.fin-trigger-guidance.description.placeholder',
      );
    } else {
      return this.intl.t('workflow-connector.builder.body.general.description.placeholder');
    }
  }

  get app_id() {
    return this.appService.app.id;
  }

  get userUnprotectedFromSpoofing() {
    return !this.args.workflowAction.userProtectedFromSpoofing;
  }

  @action
  nextSection() {
    this.args.nextSection('request_section');
  }

  @action
  onUpdatePredicates(predicates: any) {
    this.args.workflowAction.updatePredicates(predicates);
    this.args.workflowAction.ruleset
      .get('fetchAudiencePreviewTask')
      .perform(this.ignoredAttributeTypesForPreview);
  }

  @action
  onUpdateRolePredicates(rolePredicates: any) {
    this.args.workflowAction.updateRolePredicates(rolePredicates);
    this.args.workflowAction.ruleset
      .get('fetchAudiencePreviewTask')
      .perform(this.ignoredAttributeTypesForPreview);
  }

  @action
  onUpdateOtpAuthEnabled() {
    this.args.workflowAction.updateOtpAuthEnabled();
  }

  @action
  setDefaultRulesetIfEmpty() {
    if (this.args.workflowAction.usage === 'workflow_and_inbox') {
      this.args.workflowAction.noAuthenticationChosen = null;
      return;
    }

    this.args.workflowAction.setDefaultRuleset();
  }

  @action
  toggleActionUsage() {
    if (this.args.workflowAction.usage === 'fin') {
      this.args.workflowAction.usage = 'workflow_and_inbox';
      this.args.workflowAction.noAuthenticationChosen = null;
    } else {
      this.args.workflowAction.usage = 'fin';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::General': typeof General;
    'workflow-connector/builder/body/sections/general': typeof General;
  }
}

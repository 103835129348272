/* import __COLOCATED_TEMPLATE__ from './multilingual-workflow-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  componentAttrs?: {
    readOnly?: boolean;
  };
  readOnly?: boolean;
}

export default class MultilingualWorkflowToggle extends Component<Args> {
  @service declare contentEditorService: $TSFixMe;

  get botData() {
    return this.contentEditorService.activeObject.botData;
  }

  get isMultilingualEnabled(): boolean {
    return this.botData.multilingualEnabled;
  }

  @action
  toggleMultilingualEnabled() {
    this.botData.multilingualEnabled = !this.isMultilingualEnabled;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'workflows/graph-editor/trigger-info-node/multilingual-workflow-toggle': typeof MultilingualWorkflowToggle;
    'Workflows::GraphEditor::TriggerInfoNode::MultilingualWorkflowToggle': typeof MultilingualWorkflowToggle;
  }
}

/* import __COLOCATED_TEMPLATE__ from './user-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';

import type UserSummary from 'embercom/objects/inbox/user-summary';

interface Signature {
  Args: { user: UserSummary | undefined; onSelect: (user: UserSummary) => any };
}

export default class UserSelector extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::UserSelector': typeof UserSelector;
  }
}

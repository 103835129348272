/* import __COLOCATED_TEMPLATE__ from './outward-connector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import WorkflowsEdgeGenerator from 'embercom/objects/workflows/graph-editor/edge/generator';
import type Store from '@ember-data/store';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Edge from 'graph-editor/models/graph-editor/edge';
import type GraphLayout from 'embercom/objects/workflows/graph-editor/graph-layout';
import type { Yieldable } from 'ember-concurrency';
import type PathConfig from 'embercom/objects/visual-builder/configuration/path';
import type Node from 'graph-editor/models/graph-editor/node';

export interface Args {
  readOnly: boolean;
  connectionPoint: ConnectionPoint;
  editorState: EditorState;
  layout: GraphLayout;
  index?: number;
  stepIndex: number;
  updateConnectionPoints: (node: Node) => Generator<Yieldable<void>, void, unknown>;
  shouldShowStepPaywalls: boolean;
  pathConfig: PathConfig;
  node: Node;
  isGroupConnectionPoint: boolean;
}

export interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class OutwardConnector extends Component<Signature> {
  @service declare store: Store;

  get isConnectable() {
    return (
      !this.args.readOnly &&
      (!this.args.connectionPoint.isConnected ||
        this.args.connectionPoint.isTerminal ||
        this.args.connectionPoint.edge?.toGroup.isPlaceholder)
    );
  }

  get canShowConnectedEdgeMenu() {
    return this.args.connectionPoint.isConnected;
  }

  get isConnectionPointInvalid() {
    return (
      this.args.editorState.shouldShowValidations &&
      !this.args.connectionPoint.validations.attrs.edge.isValid
    );
  }

  @action
  onStartDraggingEdge(edge: Edge) {
    edge.generator = new WorkflowsEdgeGenerator(edge);
  }

  @action
  setElement(element: HTMLElement) {
    this.args.layout.setConnectionPointElement(this.args.connectionPoint, element);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::Node::OutwardConnector': typeof OutwardConnector;
    'workflows/graph-editor/node/outward-connector': typeof OutwardConnector;
  }
}

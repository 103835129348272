/* import __COLOCATED_TEMPLATE__ from './side-sheet-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';

interface Args {
  step: FinCustom;
  simpleMode: boolean;
  readOnly: boolean;
}
interface Signature {
  Element: HTMLFormElement;
  Args: Args;
}

export default class SideSheetHeader extends Component<Signature> {
  @action
  onFocusOut() {
    this.args.step.name = this.args.step.name.trim();
  }

  @action
  onUpdate(newName: string) {
    this.args.step.name = newName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::SideSheetHeader': typeof SideSheetHeader;
    'workflows/graph-editor/node-items/steps/fin-custom/side-sheet-header': typeof SideSheetHeader;
  }
}

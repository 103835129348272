/* import __COLOCATED_TEMPLATE__ from './trial-days-left.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  trialPlanName: string;
  daysLeftOfTrial: number;
  showWarning?: boolean;
  finIsLive?: boolean;
}

export default class TrialDaysLeft extends Component<Args> {
  @service declare intl: IntlService;

  get finalDay() {
    return this.args.daysLeftOfTrial === 0;
  }

  get nearingEnd() {
    return this.args.daysLeftOfTrial <= 3 && this.args.daysLeftOfTrial >= 0;
  }

  get duringTrial() {
    return this.args.daysLeftOfTrial >= 0;
  }

  get trialEnded() {
    return this.args.daysLeftOfTrial < 0;
  }

  get finBannerKey() {
    let key = 'during_trial';
    if (this.finalDay) {
      key = this.args.finIsLive ? 'final_day_live' : 'final_day_not_live';
    } else if (this.nearingEnd && this.args.finIsLive) {
      key = '3_or_less_days_left';
    } else if (this.duringTrial) {
      key = 'during_trial';
    } else if (this.trialEnded) {
      key = 'trial_ended';
    } else {
      key = 'cancelled_trial';
    }
    return `top-banner-selector.standalone.${key}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TrialDaysLeft: typeof TrialDaysLeft;
  }
}

/* import __COLOCATED_TEMPLATE__ from './inbox-preview.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import templateOnlyComponent from '@ember/component/template-only';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  conversation: Conversation | undefined;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const InboxPreview = templateOnlyComponent<Signature>();
export default InboxPreview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::InboxPreview': typeof InboxPreview;
    'workflows/preview/inbox-preview': typeof InboxPreview;
  }
}

/* import __COLOCATED_TEMPLATE__ from './welcome-helpdesk.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { ref } from 'ember-ref-bucket';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import ENV from 'embercom/config/environment';
import type RouterService from '@ember/routing/router-service';
import { markViewAsSeen } from 'embercom/lib/admin-view-tracking';
import { INBOX_PAGEVIEW_KEY } from 'embercom/lib/operator/resolution-bot/constants';

interface Signature {
  Args: any;
  Element: never;
}

export default class WelcomeHelpdesk extends Component<Signature> {
  @service declare appService: any;
  @service declare store: Store;
  @service intercomEventService: any;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @ref('helpdeskVideoElement') videoElement!: HTMLVideoElement;
  @tracked isMessengerVideoPlaying = false;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'welcome_helpdesk_component',
    });
    if (!this.onboardingStep?.completed) {
      this.markStepAsCompleted();
    }
  }

  get app() {
    return this.appService.app;
  }

  get onboardingStep() {
    return this.store.peekRecord('onboarding/home/step', 'display_only_meet_the_helpdesk');
  }

  get title() {
    return this.intl.t('operator.welcome-helpdesk.title-copilot');
  }

  get subtitle() {
    return this.intl.t('operator.welcome-helpdesk.sub-title-copilot');
  }

  get videoThumbnail1xAssetUrl() {
    return assetUrl('/assets/images/onboarding/guide-library/inbox_video_preview@1x.png');
  }

  get videoThumbnail2xAssetUrl() {
    return assetUrl('/assets/images/onboarding/guide-library/inbox_video_preview@2x.png');
  }
  get setupButtonLabel() {
    return this.intl.t('operator.welcome-helpdesk.setup-button-copilot');
  }

  get wistiaId() {
    return 'lcsywh5c4n';
  }

  autoPlayVideo = modifier((videoElement: HTMLVideoElement) => {
    let readyFn = () => {
      if (videoElement.readyState >= 2 && ENV.environment !== 'test') {
        videoElement.currentTime = 0;
        videoElement.muted = true;
        videoElement.play();
      }
    };

    let playFn = () => {
      this.isMessengerVideoPlaying = true;
    };

    let endedFn = () => {
      this.isMessengerVideoPlaying = false;
    };

    videoElement.addEventListener('loadeddata', readyFn);
    videoElement.addEventListener('play', playFn);
    videoElement.addEventListener('ended', endedFn);

    return function () {
      videoElement.removeEventListener('loadeddata', readyFn);
      videoElement.removeEventListener('play', playFn);
      videoElement.removeEventListener('ended', endedFn);
    };
  });

  @action replayVideo(): void {
    if (
      this.videoElement &&
      (this.videoElement.paused || this.videoElement.ended) &&
      ENV.environment !== 'test'
    ) {
      this.videoElement.currentTime = 0;
      this.videoElement.muted = true;
      this.videoElement.play();
    }
  }

  @action
  async markStepAsCompleted() {
    this.onboardingStep?.set('state', 'completed');
    await this.onboardingStep?.saveAndRefreshGuide();
  }

  @action
  async transitionToInbox() {
    markViewAsSeen(this.store, INBOX_PAGEVIEW_KEY);
    this.router.transitionTo('inbox.workspace.inbox.inbox', this.app.id, 'shared', 'all', {
      queryParams: { product_tour_id: 535792 },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    WelcomeHelpdesk: typeof WelcomeHelpdesk;
  }
}

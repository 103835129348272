/* import __COLOCATED_TEMPLATE__ from './read-attribute-operation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type AttributeInfoResolver } from '@intercom/embercom-prosemirror-composer';

interface Args {
  resolver: AttributeInfoResolver;
  onUpdateNode: (state: Record<string, unknown>) => void;
  closePopover: () => void;
}

export default class ReadAttributeOperation extends Component<Args> {
  @action
  onUpdateAttributeIdentifier(identifier: string): void {
    this.args.onUpdateNode({
      subjectIdentifier: identifier,
    });

    this.args.closePopover();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AiPromptDataPopover::ReadAttributeOperation': typeof ReadAttributeOperation;
  }
}

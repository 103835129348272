/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import Component from '@glimmer/component';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type FinCustomConfiguration from 'embercom/objects/visual-builder/configuration/step/fin-custom';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

interface Arguments {
  step: FinCustom;
  stepConfig: FinCustomConfiguration;
  editorState: EditorState;
  isReadOnly: boolean;
  onAddOutputParam: (outputParam: OutputParam) => AttributeDescriptor;
  onUpdateOutputParam: (outputParam: OutputParam) => void;
  onRemoveOutputParam: (outputParam: OutputParam) => void;
  outputParams: OutputParam[];
}
export default class Settings extends Component<Signature> {
  @service appService: any;
  @service attributeService: any;
  @service store: any;
  @service contentEditorService: any;

  @tracked openInserters: any;
  @tracked selectedTab = 'configure_section';
  @tracked allowMultiTurn = this.args.step.allowMultiTurn;
  @tracked allowContentSearch = this.args.step.allowContentSearch;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    // Initialize allowMultiTurn if not already set
    if (this.args.step.allowMultiTurn === undefined) {
      this.args.step.set('allowMultiTurn', true);
      this.allowMultiTurn = true;
    }
  }

  get app() {
    return this.appService.app;
  }

  get canShowContentSearchToggle() {
    return this.app.canUseFeature('answerbot-fin-agentic-content-search');
  }

  get shouldShowAgenticSettings() {
    return this.canShowContentSearchToggle;
  }

  @action
  onSelectionChange(sectionId: string) {
    this.selectedTab = sectionId;
  }

  @action
  toggleAllowMultiTurn() {
    this.allowMultiTurn = !this.allowMultiTurn;
    this.args.step.set('allowMultiTurn', this.allowMultiTurn);
  }

  @action
  toggleAllowContentSearch() {
    this.allowContentSearch = !this.allowContentSearch;
    this.args.step.set('allowContentSearch', this.allowContentSearch);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/fin-custom/settings': typeof Settings;
  }
}

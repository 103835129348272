/* import __COLOCATED_TEMPLATE__ from './side-sheet.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {}

const SideSheet = templateOnlyComponent<Args>();
export default SideSheet;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffWithJavascript::SideSheet': typeof SideSheet;
    'workflows/graph-editor/node-items/steps/handoff-with-javascript/side-sheet': typeof SideSheet;
  }
}

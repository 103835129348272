/* import __COLOCATED_TEMPLATE__ from './ai-prompt-data-typeahead.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import type { TypeaheadMatchResult } from '@intercom/embercom-prosemirror-composer';
import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';
import { NodeSelection } from 'prosemirror-state';

type Operation = 'use_action' | 'read_attribute' | 'update_attribute';

interface Args {
  composer: Composer;
  matchResult: TypeaheadMatchResult | null;
  registerDelegate: (f: () => void) => void;
  deregisterDelegate: (f: () => void) => void;
  attrs: {
    allowedOperations?: Operation[];
  };
}

export default class AiPromptDataTypeahead extends Component<Args> {
  get query(): string {
    return this.args.matchResult?.text || '';
  }

  get items(): { label: string; icon: string }[] {
    if (this.query.search('@') === -1 || this.query === '@ ') {
      return [];
    }

    let searchTerm = this.query.replace('@', '');

    let allowedOperations = this.args.attrs.allowedOperations;

    let items = [
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      { id: 'use_action', label: 'Use an action', icon: 'webhook' },
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      { id: 'read_attribute', label: 'Read an attribute', icon: 'attribute-titles' },
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      { id: 'update_attribute', label: 'Update an attribute', icon: 'path-up' },
    ];

    items = allowedOperations?.length
      ? items.filter((item) => allowedOperations?.includes(item.id as Operation))
      : items;

    if (searchTerm) {
      return items.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    return items;
  }

  @action
  insertAiPromptData(data: { id: string }): void {
    let prosemirrorState = this.args.composer.state.prosemirrorState;

    if (prosemirrorState && this.args.matchResult) {
      let spec = prosemirrorState.schema.nodes.aiPromptData;
      let { tr } = prosemirrorState;

      // Delete the @ and any typed text
      tr.delete(this.args.matchResult.range.from, this.args.matchResult.range.to);

      // Create and insert the variable node
      let node = spec.createAndFill({
        operation: data.id,
      });
      tr.insert(this.args.matchResult.range.from, node);

      // Select the node we just inserted
      let nodePos = this.args.matchResult.range.from;
      let nodeSelection = NodeSelection.create(tr.doc, nodePos);
      tr.setSelection(nodeSelection);

      this.args.composer.commands.dispatch(tr);
      this.args.composer.commands.focus();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AiPromptDataTypeahead': typeof AiPromptDataTypeahead;
    'workflows/graph-editor/node-items/steps/fin-custom/ai-prompt-data-typeahead': typeof AiPromptDataTypeahead;
  }
}

/* import __COLOCATED_TEMPLATE__ from './side-sheet-body.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type WaitForCallback from 'embercom/models/operator/visual-builder/step/wait-for-callback';
import { DEFAULT_REQUEST } from 'embercom/models/operator/visual-builder/step/wait-for-callback';
import { type AsyncCallbackRequestAttribute } from '../wait-for-callback';
import { isJsonValid } from '../../../helpers/template-helpers';
import { tracked } from '@glimmer/tracking';

interface Arguments {
  step: WaitForCallback;
  readOnly: boolean;
  asyncCallbackRequestAttributesAsArray: AsyncCallbackRequestAttribute[];
  fetchExampleRequestsAsDescriptor: () => Promise<void>;
  isFetchingExampleRequests: boolean;
}

export const STATIC_LINK = 'https://api.intercom.io/hooks/workflows/trigger_step/';
export const HAS_ONLY_SPECIAL_CHARACTERS_REGEX = /^[^a-zA-Z0-9]*$/;
export const readOnlyPrefix = '{\n  "data": \n    ';
export const readOnlySuffix = '\n}';

export default class SideSheetBody extends Component<Arguments> {
  @service intl!: IntlService;
  @service appService: any;
  @service store: any;
  @tracked isCopyExampleRequestDisabled = false;

  displayExampleRequest = this.args.step.exampleRequest
    ? `${readOnlyPrefix}${this.args.step.exampleRequest}${readOnlySuffix}`
    : DEFAULT_REQUEST;

  get title() {
    return this.intl.t('operator.workflows.visual-builder.wait-for-callback.title-with-name', {
      name: this.args.step.name,
    });
  }

  get webhookUrl() {
    return `${STATIC_LINK}${this.appService.app.id}_${this.args.step.externalStepId}/`;
  }

  get isJsonValid() {
    return !HAS_ONLY_SPECIAL_CHARACTERS_REGEX.test(this.args.step.exampleRequest);
  }

  get isJsonParsedCorrectly() {
    return isJsonValid(this.args.step.exampleRequest);
  }

  get isPreviewDisabled() {
    return !this.args.step.exampleRequest || !this.isJsonValid || !this.isJsonParsedCorrectly;
  }

  get codeBlockDynamicReadOnlyRanges() {
    return [{ firstLines: 2, lastLines: 1 }];
  }

  get columns() {
    let columns = [
      {
        label: this.intl.t('workflow-connector.builder.body.response.fin.redaction.table.data'),
        valuePath: 'name',
      },
      {
        label: this.intl.t('workflow-connector.builder.body.response.fin.redaction.table.example'),
        valuePath: 'example',
      },
      {
        label: this.intl.t('workflow-connector.builder.body.response.fin.redaction.table.format'),
        valuePath: 'format',
      },
    ];
    return columns;
  }

  get fullExampleRequest() {
    return this.args.step.exampleRequest
      ? `${readOnlyPrefix}${this.args.step.exampleRequest}${readOnlySuffix}`
      : DEFAULT_REQUEST;
  }

  @action
  highlightCodeBlock(event: Event) {
    if (this.isCopyExampleRequestDisabled) {
      event.preventDefault();
      return;
    }

    requestAnimationFrame(() => {
      let codeBlockElement = document.querySelector('.CodeMirror .CodeMirror-code');

      if (!codeBlockElement) {
        return;
      }

      let selection = window.getSelection();
      if (selection) {
        let range = document.createRange();
        range.selectNodeContents(codeBlockElement);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    });
  }

  @action
  updateExampleRequest(value: string) {
    try {
      let parsedData = JSON.parse(value).data;
      let stringifyExampleRequest = JSON.stringify(parsedData, null, 6).replace(/\n}$/, '\n    }');
      this.args.step.exampleRequest = stringifyExampleRequest;
      this.isCopyExampleRequestDisabled = false;
    } catch (e) {
      this.isCopyExampleRequestDisabled = true;
      return e;
    }
  }

  @action
  async preview() {
    await this.args.fetchExampleRequestsAsDescriptor();
    let lastTableRow = document.querySelector('table tbody > tr:last-child');
    lastTableRow?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::WaitForCallback::SideSheetBody': typeof SideSheetBody;
    'workflows/graph-editor/node-items/steps/wait-for-callback/side-sheet-body': typeof SideSheetBody;
  }
}

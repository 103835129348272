/* import __COLOCATED_TEMPLATE__ from './custom-action-fields.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import type WorkflowConnectorConfiguration from 'embercom/objects/visual-builder/configuration/step/workflow-connector';
import type WorkflowConnector from 'embercom/models/operator/visual-builder/step/workflow-connector';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { get } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import type WorkflowActionParam from 'embercom/models/workflow-connector/workflow-action-param';
import type JiraWorkflowActionParam from 'embercom/models/workflow-connector/jira-workflow-action-param';
import { tracked } from '@glimmer/tracking';
import generateUUID from 'embercom/lib/uuid-generator';

interface Arguments {
  step: WorkflowConnector;
  stepConfig: WorkflowConnectorConfiguration;
}
export default class CustomActionFields extends Component<Arguments> {
  @service store: any;
  @service declare appService: any;
  @service notificationsService: any;
  @service intl: any;

  @tracked declare workflowActionParams: WorkflowActionParam[] | JiraWorkflowActionParam[];
  @tracked declare workflowAction: any;
  @tracked declare showRequestErrorBanner: boolean;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    taskFor(this.fetchActionParamDefinitions).perform();
  }

  @dropTask
  *fetchActionParamDefinitions(parameterContext: string = JSON.stringify({})): TaskGenerator<void> {
    try {
      yield get(
        `/ember/workflow_connector/insertable_actions/${this.args.step.action?.id}/workflow_action_params`,
        {
          app_id: this.appService.app.id,
          parameter_context: parameterContext,
        },
      ).then((response: any) => {
        this.constructResponseAndPushToStore(response);
        taskFor(this.fetchWorkflowAction).perform();
      });
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t(
          'operator.workflows.visual-builder.workflow-connector-settings.error-retrieving-fields',
          { actionName: this.args.step.action?.name },
        ),
      );
    }
  }

  @dropTask
  *fetchWorkflowAction(): TaskGenerator<void> {
    this.workflowAction = yield this.store.find(
      'workflow-connector/action',
      this.args.step.action?.id,
    );

    let action_name = this.args.step.action!.name;

    if (action_name.includes('GitHub')) {
      this.mapGitHubFields(JSON.parse(this.workflowAction.body));
    } else if (action_name === 'Jira Create Comment') {
      this.mapJiraCreateCommentFields(JSON.parse(this.workflowAction.body));
    }
  }

  mapGitHubFields(bodyJson: any) {
    this.workflowActionParams.forEach((param: WorkflowActionParam) => {
      if (param.isArray) {
        param.value = bodyJson[param.key]?.join(',');
      } else {
        param.value = bodyJson[param.key];
      }
    });
  }

  mapJiraCreateCommentFields(bodyJson: any) {
    let param = this.workflowActionParams.find(
      (param: JiraWorkflowActionParam) => param.key === 'comment',
    ) as JiraWorkflowActionParam;
    param.value = param.extractJiraCreateCommentField(bodyJson);
  }

  get app() {
    return this.appService.app;
  }

  get shouldShowJiraForTickets() {
    return this.app.hasActiveJiraIntegration;
  }

  get shouldShowGitHub() {
    return this.app.canUseGithubWorkflowsAlpha && this.app.hasActiveGithubIntegration;
  }

  constructResponseAndPushToStore(response: any) {
    let actionName = this.args.step.action!.name;
    let actionNameSelector = actionName.replace(/\s+/g, '-').toLowerCase();
    let tooltipFields: any[] = [];
    let modelName = 'workflow-connector/workflow-action-param';

    switch (actionName) {
      case 'GitHub Create Issue':
        tooltipFields = ['labels', 'assignees'];
        break;
      case 'Jira Create Issue':
      case 'Jira Create Comment':
        tooltipFields = ['project', 'assignee', 'reporter', 'issueType', 'priority', 'labels'];
        modelName = 'workflow-connector/jira-workflow-action-param';
        this.showRequestErrorBanner = response.errors.length > 0;
        response = response.data_attributes;
        break;
      default:
        break;
    }
    this.store.peekAll(modelName)?.forEach((param: any) => {
      this.store.unloadRecord(param);
    });

    this.workflowActionParams = response.map((param: any) => {
      param = this.addTranslationTitleAndTooltip(param, actionNameSelector, tooltipFields);
      return this.store.push(this.store.normalize(modelName, param));
    });
  }

  addTranslationTitleAndTooltip(param: any, actionName: string, tooltipFields: any[] = []) {
    param['id'] = generateUUID();
    param['displayed_title'] =
      `operator.workflows.visual-builder.${actionName}.settings.${param['key']}`;
    let field = tooltipFields.find((field) => param['key'] === field);
    if (field) {
      param['tooltip'] =
        `operator.workflows.visual-builder.${actionName}.settings.${field}-description`;
    }
    return param;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::CustomAction::CustomActionFields': typeof CustomActionFields;
    'workflows/graph-editor/node-items/steps/custom-action/custom-action-fields': typeof CustomActionFields;
  }
}

/* import __COLOCATED_TEMPLATE__ from './preview-url-builder.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  previewableWorkflow: any;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [
      {
        messengerUrl: string;
      },
    ];
  };
  Element: HTMLElement;
}

export default class PreviewUrlBuilder extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get appId() {
    return this.appService.app.id;
  }

  get previewWorkflowInstanceId() {
    return this.args.previewableWorkflow.latestWorkflowInstanceId;
  }

  get messengerUrl() {
    // Add `&local_client=true` to the end of the URL to see changes to intercom-js code in the preview.
    let iframeUrl = `${window.location.origin}/hosted_messenger/${this.appId}/workflow_preview?preview_only=true`;
    iframeUrl += `&preview_workflow_instance_id=${this.previewWorkflowInstanceId}`;

    return iframeUrl;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::PreviewUrlBuilder': typeof PreviewUrlBuilder;
    'workflows/preview/preview-url-builder': typeof PreviewUrlBuilder;
  }
}

/* import __COLOCATED_TEMPLATE__ from './attribute-fields.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import type WorkflowConnectorConfiguration from 'embercom/objects/visual-builder/configuration/step/workflow-connector';
import { inject as service } from '@ember/service';
import type JiraWorkflowActionParam from 'embercom/models/workflow-connector/jira-workflow-action-param';

type Arguments = {
  item: JiraWorkflowActionParam;
  errorFields: any;
  stepConfig: WorkflowConnectorConfiguration;
  fetchActionParamDefinitions: (parameterContext: string) => any;
};

export default class AttributeFields extends Component<Arguments> {
  @tracked declare openSectionId: string | null;
  @service declare appService: any;
  @service store: any;

  get app() {
    return this.appService.app;
  }

  @action
  onOpenSectionChange(sectionId: string): void {
    this.openSectionId = sectionId;
  }

  get parameterContext() {
    if (this.args.item.inputType === 'project') {
      return JSON.stringify([{ key: this.args.item.key, value: this.args.item.value }]);
    }

    let context = this.store
      .peekAll('workflow-connector/jira-workflow-action-param')
      .map((param: any) => {
        return { key: param['key'], value: param['value'] };
      });
    return JSON.stringify(context);
  }

  @action
  onSelectedChangeWithRefresh(newValue: any): void {
    this.args.item.onSelectParamChange(newValue);
    if (this.args.item.refreshRequest) {
      taskFor(this.args.fetchActionParamDefinitions).perform(this.parameterContext);
    }
  }

  get isSingleLineComposer() {
    return (
      !['json', 'textarea'].includes(this.args.item.inputType) &&
      this.args.item.key !== 'description'
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::CustomAction::Jira::AttributeFields': typeof AttributeFields;
    'workflows/graph-editor/node-items/steps/custom-action/jira/attribute-fields': typeof AttributeFields;
  }
}

/* import __COLOCATED_TEMPLATE__ from './block-user.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { scheduleOnce } from '@ember/runloop';
import { readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action } from '@ember/object';

export default Component.extend({
  tagName: '',
  intercomEventService: service(),
  notificationsService: service('notificationsService'),
  router: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  isBlockingUser: false,
  hasErrored: false,

  personName: reads('model.displayAs'),

  reset() {
    this.setProperties({
      isBlockingUser: false,
      hasErrored: false,
    });
  },

  didRender() {
    this._super(...arguments);
    scheduleOnce('afterRender', this, this.focusCancelButton);
  },

  focusCancelButton() {
    $('.js__block-user-cancel-button').focus();
  },

  _blockUserSucceeded() {
    if (this.options?.conversation) {
      this._removeUserConversationsFromConversationList();
    } else {
      this.router.transitionTo('apps.app.users.segments', this.app);
    }
    this.reset();
    this.notificationsService.notifyConfirmation(`${this.personName} has been blocked.`);
    this.closeModal();
  },

  _blockUserFailed() {
    this.setProperties({
      isBlockingUser: false,
      hasErrored: true,
    });
    this.notificationsService.notifyError('Something went wrong! Please try again.');
  },

  _removeUserConversationsFromConversationList() {
    if (this.options?.conversation?.isGroupConversation) {
      // This only temporarily removes the user from the visible conversation - inference (at the moment)
      // pulls it back in as there is no removal part
      this.options.conversation.removeParticipant(this.model);
    }

    return this.router.transitionTo(this.get('app.inboxBaseRoute'));
  },

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    cancel() {
      this.closeModal();
    },
    confirm() {
      let conversation = this.options?.conversation;
      let spamConversation = conversation?.isNotGroupConversation ? conversation : null;

      this.intercomEventService.trackEvent('blocked-user', {
        user_id: this.get('model.id'),
      });
      this.setProperties({
        isBlockingUser: true,
        hasErrored: false,
      });
      this.model
        .blockUser(spamConversation)
        .then(() => {
          this._blockUserSucceeded();
        })
        .catch(() => {
          this._blockUserFailed();
        });
    },
    routeToEmailForwarding() {
      this.router.transitionTo('apps.app.settings.channels.email', {
        queryParams: { tab: 'email-forwarding' },
      });
      this.closeModal();
    },
  },
});

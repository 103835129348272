/* import __COLOCATED_TEMPLATE__ from './standard-body.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import type ContextualIdentifierConfig from 'embercom/models/workflow-connector/customer-data-verification/contextual-identifier-config';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export interface Args {
  contextualIdentifierConfig: ContextualIdentifierConfig;
  section: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class StandardBody extends Component<Signature> {
  @service intercomEventService: any;
  @tracked templatedParameters: any = {};

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.requestParameters();
  }

  requestParameters() {
    Array.from(this.apiRequestConfig.attributes).map(
      (attribute) =>
        (this.templatedParameters[attribute[1]] = this.getValueforAttribute(attribute[1])),
    );
  }

  getValueforAttribute(attribute: string) {
    return this.apiRequestConfig.testResult?.testValues
      ? this.apiRequestConfig.testResult.testValues[attribute] || ''
      : '';
  }

  get apiRequestConfig() {
    return this.args.contextualIdentifierConfig.apiRequestConfig;
  }

  @action
  saveTemplatedParameter(requestParameter: string, requestValue: any) {
    this.templatedParameters[requestParameter] = requestValue;
  }

  @action
  clearTemplatedParameter() {
    this.templatedParameters = {};
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::CustomerDataVerification::StandardBody': typeof StandardBody;
    'workflow-connector/builder/body/customer-data-verification/standard-body': typeof StandardBody;
  }
}

/* import __COLOCATED_TEMPLATE__ from './use-action-operation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type AttributeInfoResolver } from '@intercom/embercom-prosemirror-composer';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  resolver: AttributeInfoResolver;
  onUpdateNode: (state: Record<string, unknown>) => void;
  closePopover: () => void;
}

export const CUSTOM_ACTION_URL = 'apps.app.settings.app-settings.custom-actions.custom-action';

export default class UseActionOperation extends Component<Args> {
  @service declare store: $TSFixMe;
  @service declare router: $TSFixMe;

  @action
  onUpdateActionIdentifier(identifier: string): void {
    this.args.onUpdateNode({
      subjectIdentifier: identifier,
    });

    this.args.closePopover();
  }

  @action
  async onClickNewCustomAction() {
    let newWorkflowAction = await this.store
      .createRecord('workflow-connector/action', { usage: 'fin' })
      .save();

    safeWindowOpen(this.router.urlFor(CUSTOM_ACTION_URL, newWorkflowAction.id), '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AiPromptDataPopover::UseActionOperation': typeof UseActionOperation;
  }
}

/* import __COLOCATED_TEMPLATE__ from './custom-action-draft-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';

export default class extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intl;

  @tracked dependentObjects = null;
  @tracked hasDependentObjects = null;
  @tracked tableData = [];

  get modalTitle() {
    return this.isChecking
      ? this.intl.t('workflow-connector.builder.draft-modal.checking-title')
      : this.intl.t('workflow-connector.builder.draft-modal.title', {
          actionName: this.args.workflowAction.name,
        });
  }

  get isChecking() {
    return this.fetchDependentObjects.isRunning;
  }

  get canMoveToDraft() {
    return !this.isChecking && this.hasDependentObjects === false;
  }

  @task({ drop: true })
  *fetchDependentObjects() {
    let action = this.args.workflowAction;
    let params = {
      app_id: this.appService.app.id,
      id: action.id,
    };
    try {
      let response = yield get(
        `/ember/workflow_connector/actions/${action.id}/find_dependent_objects`,
        params,
      );
      this.tableData = [];
      this.dependentObjects = {
        customBots: response.dependent_custom_bots,
        resolutionBots: response.dependent_resolution_bots,
        workflows: response.dependent_workflows,
        macros: response.dependent_macros,
      };
      for (let dependentObject in this.dependentObjects) {
        if (this.dependentObjects.hasOwnProperty(dependentObject)) {
          this.tableData.push(...this.dependentObjects[dependentObject]);
        }
      }
      this.hasDependentObjects = response.has_dependents;
    } catch (error) {
      this.args.onModalClose();
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.builder.draft-modal.error-message', {
          actionName: this.args.workflowAction.name,
        }),
      );
    }
  }
}

/* import __COLOCATED_TEMPLATE__ from './preview-email.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { action } from '@ember/object';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
}

export default class PreviewEmail extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: any;

  @tracked declare finPreviewAddress: string;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    taskFor(this.fetchFinPreviewAddress).perform();
  }

  @task({ drop: true })
  *fetchFinPreviewAddress(): TaskGenerator<void> {
    let finPreviewSettings = yield this.store.findRecord(
      'ai-agent/preview-settings',
      this.appService.app.id,
    );

    this.finPreviewAddress = finPreviewSettings.previewAddress;
  }

  get isFetchingFinPreviewAddress() {
    return taskFor(this.fetchFinPreviewAddress).isRunning;
  }

  @action
  trackCopyEmailAddress() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'preview_email_copy_address',
      place: 'workflow_preview',
      section: 'preview',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::PreviewEmail': typeof PreviewEmail;
    'workflows/preview/preview-email': typeof PreviewEmail;
  }
}

/* import __COLOCATED_TEMPLATE__ from './fin-custom.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type FinCustomConfiguration from 'embercom/objects/visual-builder/configuration/step/fin-custom';
import { action } from '@ember/object';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { Type } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import { type ComponentLike } from '@glint/template';
import type { Yieldable } from 'ember-concurrency';
import type AttributeDescriptorListOption from 'embercom/models/operator/visual-builder/attribute-descriptor-list-option';
import generateUUID from 'embercom/lib/uuid-generator';
import type IntlService from 'ember-intl/services/intl';
import { type Block } from 'embercom/models/common/blocks/block';

export interface ListOption {
  id: string;
  value: string;
}

export interface OutputParam {
  id?: string;
  name: string;
  description: string;
  descriptorType: Type;
  listOptions: ListOption[];
}

interface Arguments {
  step: FinCustom;
  stepConfig: FinCustomConfiguration;
  editorState: EditorState;
  readOnly: boolean;
  connectionPointComponent: ComponentLike<{ connectionPoint: ConnectionPoint }>;
  updateConnectionPoints: (node: Node) => Generator<Yieldable<void>, void, unknown>;
}

export default class FinCustomNodeStep extends Component<Arguments> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    if (this.args.step.outwardConnectionPoints.length === 0) {
      this.args.step.outwardConnectionPoints.addObject(
        this.store.createRecord('operator/visual-builder/connection-point', { type: 'fallback' }),
      );
    }
  }

  get app() {
    return this.appService.app;
  }

  get stepName() {
    return this.args.step.name;
  }

  get validationErrors() {
    return this.args.step.validations.errors;
  }

  get firstGoalBlock() {
    let blocks = this.args.step.blocks?.serialize() ?? [];
    blocks = blocks.filter((block: Block) => !!block.text);

    if (blocks.length > 0) {
      return blocks[0];
    }
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }

  get shouldShowOutputError() {
    return (
      this.args.editorState.shouldShowValidations &&
      !this.args.step.validations.attrs.outwardConnectionPoints.isValid
    );
  }

  get fallbackBranch(): ConnectionPoint {
    return this.args.step.outwardConnectionPoints.firstObject!;
  }

  get outputParams() {
    return this.args.step.finOutputParams.map((descriptor: AttributeDescriptor) => {
      return {
        name: descriptor.name,
        description: descriptor.description,
        id: descriptor.id,
        descriptorType: descriptor.type,
        listOptions: descriptor.listOptions
          .toArray()
          .map((option: AttributeDescriptorListOption) => {
            return {
              id: option.id,
              value: option.label,
            };
          }) as ListOption[],
      } as OutputParam;
    });
  }

  get isFinTaskSimpleMode() {
    return this.appService.app.canUseFinTaskSimpleMode && this.args.editorState.isFinTaskSimpleMode;
  }

  @action
  addOutputParam(param: OutputParam) {
    let attributeDescriptor = this.args.editorState.createLocalVariable(
      param.name,
      param.descriptorType ?? Type.string,
    );
    attributeDescriptor.description = param.description;

    for (let option of param.listOptions ?? []) {
      let listOption = this.store.createRecord(
        'operator/visual-builder/attribute-descriptor-list-option',
        {
          id: generateUUID(),
          label: option.value,
          descriptor: attributeDescriptor,
        },
      );

      attributeDescriptor.listOptions.pushObject(listOption);
    }

    this.args.step.finOutputParams.addObject(attributeDescriptor);

    return attributeDescriptor;
  }

  @action
  updateOutputParam(param: OutputParam) {
    this.args.step.finOutputParams = this.args.step.finOutputParams.map((descriptor) => {
      if (descriptor.id !== param.id) {
        return descriptor;
      }

      this.updateDescriptorProperties(descriptor, param);

      descriptor.listOptions = this.buildUpdatedListOptions(descriptor, param.listOptions);
      return descriptor;
    }) as $TSFixMe;
  }

  private updateDescriptorProperties(descriptor: AttributeDescriptor, param: OutputParam) {
    if (descriptor.name !== param.name) {
      descriptor.name = param.name;
    }
    if (descriptor.description !== param.description) {
      descriptor.description = param.description;
    }
    if (descriptor.type !== param.descriptorType) {
      descriptor.type = param.descriptorType;
    }
  }

  private buildUpdatedListOptions(descriptor: AttributeDescriptor, newOptions: ListOption[]) {
    return newOptions.map((option) => {
      if (option.id) {
        let existingOption = descriptor.listOptions.findBy('id', option.id);
        if (existingOption) {
          if (existingOption.label !== option.value) {
            existingOption.label = option.value;
          }
          return existingOption;
        }
      }

      return this.store.createRecord('operator/visual-builder/attribute-descriptor-list-option', {
        id: generateUUID(),
        label: option.value,
        descriptor,
      });
    });
  }

  @action
  removeOutputParam(outputParam: OutputParam) {
    let attributeDescriptor = this.args.step.finOutputParams.findBy('id', outputParam.id);
    if (!attributeDescriptor) {
      return;
    }

    this.args.step.finOutputParams = this.args.step.finOutputParams.filter(
      (param: AttributeDescriptor) => param.id !== outputParam.id,
    ) as $TSFixMe;

    this.args.editorState.deleteLocalVariable(attributeDescriptor);

    this.args.step.notifyPropertyChange('finOutputParams');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom': typeof FinCustom;
    'workflows/graph-editor/node-items/steps/fin-custom': typeof FinCustom;
  }
}

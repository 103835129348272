/* import __COLOCATED_TEMPLATE__ from './request.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlUnescape } from 'embercom/lib/html-unescape';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import {
  HTTP_METHODS,
  FIN_ACTIONS_SUPPORTED_HTTP_METHODS,
} from 'embercom/lib/custom-authentication/request-helper';
import { type TaskGenerator } from 'ember-concurrency';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type Action from 'embercom/models/workflow-connector/action';
import { ContentType } from 'embercom/models/workflow-connector/action';
import type Token from 'embercom/models/custom-authentication/token';
import type ParamDescriptor from 'embercom/models/workflow-connector/param-descriptor';
import type IntercomAttribute from 'embercom/models/attribute';
//@ts-ignore
import { sanitizeUrl } from '@intercom/pulse/lib/sanitize';

interface Args {
  workflowAction: Action;
  nextSection: (sectionId: string) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

interface ContentTypeOption {
  value: ContentType;
  text: string;
}

export default class Request extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare attributeService: any;
  @service declare intl: any;

  @tracked selectedPreviewTab = 'headers';
  @tracked isBodyAvailable = ['post', 'put', 'patch'].includes(this.args.workflowAction.httpMethod);
  @tracked customAuthenticationTokens: Token[] = [];
  @tracked selectedTokenId = this.args.workflowAction.tokenId || undefined;
  @tracked urlBlocks;
  @tracked bodyBlocks;
  @tracked bodyContentTypeLabel = ContentType.JSON;
  @tracked showWriteActionConfirmation = ['post', 'put', 'delete', 'patch'].includes(
    this.args.workflowAction.httpMethod,
  );
  @tracked bodyContentTypeOptions: ContentTypeOption[];

  private readonly supportedContentTypes = [ContentType.JSON, ContentType.TEXT];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.loadCustomAuthenticationTokens).perform();
    this.urlBlocks = this.workflowActionURLToBlocks();
    this.bodyBlocks = this.workflowActionBodyToBlocks();
    this.bodyContentTypeOptions = this.getContentTypeOptions();
    this.initializeContentTypeFromHeaders();

    if (this.args.workflowAction.httpMethod === null) {
      this.args.workflowAction.httpMethod = 'get';
    }
  }

  get app() {
    return this.appService.app;
  }

  get tokenList() {
    return [{ items: this.groupListItems }];
  }

  get groupListItems() {
    return this.customAuthenticationTokens.map((token) => {
      return {
        value: token.id,
        text: token.name,
      };
    });
  }

  workflowActionURLToBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.args.workflowAction.url || '',
        modelKey: 'common/blocks/paragraph',
      },
    ];
  }

  get localAttributes() {
    let attributes = this.args.workflowAction.paramDescriptors.map((paramDescriptor) => {
      return {
        identifier: paramDescriptor.name,
        name: paramDescriptor.name,
        templatable_identifier: paramDescriptor.name,
        noFallback: true,
      };
    });

    return [
      {
        heading: this.intl.t('workflow-connector.builder.body.request.fin-data.label'),
        attributes,
      },
    ];
  }

  get isInvalid() {
    return Boolean(this.args.workflowAction.installUrlErrors);
  }

  workflowActionBodyToBlocks() {
    if (!this.args.workflowAction.body) {
      return [];
    }

    let bodyLines = this.args.workflowAction.body.split('\n');
    let bodyBlocks = bodyLines.map((line) => {
      return {
        type: 'paragraph',
        text: line,
        class: 'no-margin',
        modelKey: 'common/blocks/paragraph',
      };
    });
    return bodyBlocks;
  }

  get supportedHttpMethods() {
    if (
      this.args.workflowAction.usage === 'fin' &&
      !this.appService.app?.canUseFeature('answerbot-fin-actions-write-actions')
    ) {
      return HTTP_METHODS.map((method) =>
        FIN_ACTIONS_SUPPORTED_HTTP_METHODS.includes(method.value)
          ? method
          : {
              ...method,
              text: `${method.text} ${this.intl.t('workflow-connector.builder.body.request.method.not-supported')}`,
              isDisabled: true,
            },
      );
    }
    return HTTP_METHODS;
  }

  get selectedToken() {
    return this.customAuthenticationTokens?.find((token) => token.id === this.selectedTokenId);
  }

  get isAppPackageAction() {
    return this.args.workflowAction.appPackageCode !== null;
  }

  get unprotectedAttributes() {
    let attributes = this.args.workflowAction.usedAttributeArray;
    return this.attributeService.attributes.filter(
      (attr: IntercomAttribute) =>
        attributes.includes(attr.identifier) && !attr.preventUpdateFromMessenger,
    );
  }

  get isFinWriteAction() {
    return this.args.workflowAction.finWriteAction;
  }

  get requestBodyPlaceholder(): string {
    return this.bodyContentTypeLabel === ContentType.JSON
      ? this.intl.t('workflow-connector.builder.body.request.request-body.placeholder-json')
      : this.intl.t('workflow-connector.builder.body.request.request-body.placeholder-text');
  }

  get requestBodyTooltip(): string {
    return this.bodyContentTypeLabel === ContentType.JSON
      ? this.intl.t('workflow-connector.builder.body.request.request-body.description-json')
      : this.intl.t('workflow-connector.builder.body.request.request-body.description-text');
  }

  get hasInvalidBody() {
    return !this.args.workflowAction.isRequestBodyValid;
  }

  get bodyValidationMessage() {
    if (this.hasInvalidBody) {
      return this.intl.t('workflow-connector.builder.body.request.body.validation-error-json');
    }
    return null;
  }

  @action
  toggleFinWriteAction() {
    this.args.workflowAction.toggleFinWriteAction();
  }

  @action
  previousSection() {
    this.args.nextSection('general_section');
  }

  @action
  nextSection() {
    this.args.nextSection('testing_section');
  }

  private updateContentTypeHeader(value: ContentType) {
    let headers = this.args.workflowAction.headers || [];
    let existingContentTypeHeader = headers.find((header) => header.name === 'Content-Type');

    if (existingContentTypeHeader) {
      existingContentTypeHeader.value = value;
      return;
    }

    if (headers.length === 0) {
      let contentTypeHeader = this.store.createFragment('workflow-connector/action-header', {
        name: 'Content-Type',
        value,
      });
      this.args.workflowAction.headers = [contentTypeHeader];
    }
  }

  @action
  setActionHttpMethod(httpMethod: string) {
    this.args.workflowAction.httpMethod = httpMethod;
    this.isBodyAvailable = ['post', 'put', 'patch'].includes(httpMethod);
    if (!this.isBodyAvailable) {
      this.selectedPreviewTab = 'headers';
    } else {
      this.updateContentTypeHeader(this.bodyContentTypeLabel);
    }
    this.showWriteActionConfirmation = ['post', 'put', 'delete', 'patch'].includes(
      this.args.workflowAction.httpMethod,
    );
  }

  @action
  changePreviewTab(selectedTab: string) {
    this.selectedPreviewTab = selectedTab;
  }

  @action
  onUrlComposerChange(newBlocksDoc: { blocks: { text: string }[] }) {
    let blocks = newBlocksDoc.blocks;
    let processedBlocks = sanitizeUrl(htmlUnescape(blocks[0].text));
    this.args.workflowAction.url = processedBlocks.toString();
  }

  @action
  onBodyComposerChange(newBlocksDoc: { blocks: { text: string }[] }) {
    let blocks = newBlocksDoc.blocks;
    let blockTexts = blocks.map((block) => htmlUnescape(block.text));
    let processedBlocks = blockTexts.join('\n');
    this.args.workflowAction.body = processedBlocks.toString();
  }

  @action
  updateWorkflowToken(tokenId: string) {
    this.args.workflowAction.tokenId = tokenId;
    this.selectedTokenId = tokenId;
  }

  @action
  clearSelectedToken() {
    this.args.workflowAction.tokenId = null;
    this.selectedTokenId = undefined;
  }

  @action
  updateParamDescriptor(
    index: string,
    name: string,
    description: string,
    type: string,
    required: boolean,
    defaultValue: string,
  ) {
    if (index) {
      let paramDescriptor = this.args.workflowAction.paramDescriptors.find((x) => x.name === index);
      if (paramDescriptor) {
        paramDescriptor.setProperties({
          name,
          description,
          type,
          required,
          defaultValue,
        });
      }
    } else {
      this.args.workflowAction.paramDescriptors.pushObject({
        name,
        description,
        type,
        required,
        defaultValue,
      } as ParamDescriptor);
    }
  }

  @action
  deleteParamDescriptor(index: string) {
    let paramDescriptor = this.args.workflowAction.paramDescriptors.find((x) => x.name === index);
    if (paramDescriptor) {
      this.args.workflowAction.paramDescriptors.removeObject(paramDescriptor);
    }
  }

  @action
  onBodyContentTypeSelect(selectedType: ContentType) {
    this.bodyContentTypeLabel = selectedType;
    if (selectedType !== ContentType.DEFAULT) {
      this.updateContentTypeHeader(selectedType);
      this.bodyContentTypeOptions = this.getContentTypeOptions().filter(
        (option) => option.value !== ContentType.DEFAULT,
      );
    }
  }

  @task({ drop: true })
  *loadCustomAuthenticationTokens(): TaskGenerator<void> {
    this.customAuthenticationTokens = yield this.store.findAll('custom-authentication/token');
  }

  private getContentTypeOptions() {
    return [
      {
        value: ContentType.DEFAULT,
        text: this.intl.t(
          'workflow-connector.builder.body.request.request-body.content-type-default',
        ),
      },
      { value: ContentType.JSON, text: 'JSON' },
      {
        value: ContentType.TEXT,
        text: this.intl.t('workflow-connector.builder.body.request.request-body.content-type-text'),
      },
    ];
  }

  private initializeContentTypeFromHeaders(): void {
    let headers = this.args.workflowAction.headers || [];
    let existingContentTypeHeader = headers.find((header) => header.name === 'Content-Type');

    if (
      existingContentTypeHeader?.value &&
      this.supportedContentTypes.includes(existingContentTypeHeader.value as ContentType)
    ) {
      this.bodyContentTypeLabel = existingContentTypeHeader.value as ContentType;
    } else if (this.args.workflowAction.state === 'live') {
      this.bodyContentTypeLabel = ContentType.DEFAULT;
    } else {
      this.bodyContentTypeLabel = ContentType.JSON;
    }

    if (this.bodyContentTypeLabel !== ContentType.DEFAULT) {
      this.bodyContentTypeOptions = this.getContentTypeOptions().filter(
        (option) => option.value !== ContentType.DEFAULT,
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Request': typeof Request;
    'workflow-connector/builder/body/sections/request': typeof Request;
  }
}
